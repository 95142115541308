import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SoftwareVersion } from '../../../models/software-version';
import { TranslateService } from '@ngx-translate/core';
import { TableElement } from '@onyx/core';
import { Software } from '../../../models/software';

@Component({
  selector: 'app-edit-software-add-dialog',
  templateUrl: './edit-software-add-dialog.component.html'
})
export class EditSoftwareAddDialogComponent {
  public userSelection: SoftwareVersion[] | null = null;
  public selectedSoftware: Software | null = null;
  public applicableSoftwareVersions: SoftwareVersion[] | null = null;

  constructor(
    public _translateService: TranslateService,
    private _dialogRef: MatDialogRef<EditSoftwareAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      software: Software[];
      getSoftwareVersions: (software: string) => Observable<SoftwareVersion[]>;
    }
  ) {}

  public populateSoftwareVersions(): void {
    this.applicableSoftwareVersions = null;
    if (this.selectedSoftware) {
      this.data
        .getSoftwareVersions(this.selectedSoftware?.name)
        .subscribe(list => (this.applicableSoftwareVersions = list));
    }
  }

  public getTableStructure(): TableElement<SoftwareVersion>[] {
    return [
      {
        field: 'version',
        header: this._translateService.instant('software.version'),
        filterable: true
      },
      {
        field: 'variant',
        header: this._translateService.instant('software.variant'),
        filterable: true
      },
      {
        field: 'vehicleType',
        header: this._translateService.instant('software.vehicleType'),
        filterable: true
      }
    ];
  }

  public preventSave(): boolean {
    return !this.userSelection?.length;
  }

  public select(selection: SoftwareVersion[]): void {
    this.userSelection = selection;
  }

  public submit(): void {
    if (this.userSelection?.length) {
      this._dialogRef.close({
        software: this.selectedSoftware,
        version: this.userSelection[0]
      });
    }
  }

  public cancel(): void {
    this._dialogRef.close(null);
  }
}
