import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DigitalTwinNodeHistory } from '../../models/digital-twin-node-history';
import { MatDialog } from '@angular/material/dialog';
import { DigitalTwinApiService } from '../../services/digital-twin-api.service';
import { ShowDiffDialogComponent } from '../show-diff-dialog/show-diff-dialog.component';
import { DigitalTwinNode } from '../../models/digital-twin-node';

@Component({
  selector: 'app-node-history',
  templateUrl: './node-history.component.html'
})
export class NodeHistoryComponent {
  @Input() public node: DigitalTwinNode | null = null;
  @Input() public history: DigitalTwinNodeHistory[] | null = null;
  @Output() public revert = new EventEmitter<number>();

  constructor(
    private _dialog: MatDialog,
    private _service: DigitalTwinApiService
  ) {}

  public showDiff(
    diffRow: DigitalTwinNodeHistory,
    historyItems: DigitalTwinNodeHistory[]
  ): void {
    const rowIdx = historyItems.indexOf(diffRow);
    const previousRowIdx = rowIdx + 1;
    const startVersion =
      previousRowIdx < historyItems.length
        ? historyItems[previousRowIdx].version
        : -1;

    this._dialog.open(ShowDiffDialogComponent, {
      data: {
        versionA: startVersion,
        versionB: diffRow.version,
        modificationNote: diffRow.modificationNote,
        diff$: this._service.getNodeDiff({
          a: {
            nodeId: diffRow.id,
            version: startVersion
          },
          b: {
            nodeId: diffRow.id,
            version: diffRow.version
          }
        })
      },
      panelClass: 'info'
    });
  }
}
