<form [formGroup]="_form" (ngSubmit)="submit()">
  <div mat-dialog-title>
    <ui-title
      size="large"
      [title]="'requestOverview.createByTdf.dialogtitle' | translate"
      state="success"
      [svgIcon]="true"
      icon="icon_AGV"
    ></ui-title>
  </div>
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>{{ 'common.vin' | translate }}</mat-label>
      <input
        matInput
        maxlength="50"
        required
        formControlName="vin"
        tabindex="1"
      />
    </mat-form-field>
    <ui-callout
      *ngIf="_form.controls['vin'].hasError('vinConflict')"
      state="error"
      [title]="'requestOverview.createByTdf.vinConflict' | translate"
    >
    </ui-callout>
    <app-drag-and-drop-zone
      formControlName="file"
      accept=".tdf, text/json"
    >
    </app-drag-and-drop-zone>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button type="button" (click)="cancel()">
      {{ 'common.cancel' | translate }}
    </button>
    <button mat-flat-button type="submit" [disabled]="_form.invalid">
      {{ 'requestOverview.createByTdf.apply' | translate }}
    </button>
  </mat-dialog-actions>
</form>
