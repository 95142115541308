import { InjectionToken } from '@angular/core';

export interface AppConfig {
  cloudApiUri: string;
  redirectUri: string;
  identity: {
    clientId: string;
    scopes: string;
    authority: string;
    authWellknownEndpointUrl: string;
    redirectUrl: string;
    securedEndpoints: string[];
  };
}

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
