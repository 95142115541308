import { Component } from '@angular/core';
import { DigitalTwinNodeTypeEnum } from '../models/digital-twin-node-type.enum';
import { DigitalTwinApiService } from '../services/digital-twin-api.service';
import { debounceTime, Observable } from 'rxjs';
import { GetNodesResponse } from '../models/get-nodes-response';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  public _nodes$: Observable<GetNodesResponse>;
  public _nodeTypeEnum = DigitalTwinNodeTypeEnum;

  public searchControl = new FormControl<string>('', { nonNullable: true });
  public selectedSearchType = 'vin';

  constructor(private _service: DigitalTwinApiService) {
    this._nodes$ = this._service.searchNode({
      page: 1,
      size: -1,
      parentId: -1
    });

    this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      this.search(value);
    });
  }

  public selectSearchType(type: string): void {
    this.selectedSearchType = type;
    this.search(this.searchControl.value);
  }

  public search(value: string): void {
    if (value.length > 0) {
      this._nodes$ = this._service.searchNode({
        page: 1,
        size: -1,
        nameSearchKey: this.selectedSearchType === 'name' ? value : undefined,
        vinSearchKey: this.selectedSearchType === 'vin' ? value : undefined
      });
    } else {
      this._nodes$ = this._service.searchNode({
        page: 1,
        size: -1,
        parentId: -1
      });
    }
  }
}
