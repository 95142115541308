import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'app-drag-and-drop-zone',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DragAndDropZoneComponent),
      multi: true
    }
  ],
  templateUrl: './drag-and-drop-zone.component.html',
  styleUrl: './drag-and-drop-zone.component.scss'
})
export class DragAndDropZoneComponent implements ControlValueAccessor {
  public inputControl: FormControl<File | null> = new FormControl<File | null>(
    null
  );
  public writeValue(emails: File | null): void {
    this.internalValue = emails;
  }
  public registerOnChange(fn: (emails: File | null) => void = () => {}): void {
    this.valuesChange = fn;
  }
  public registerOnTouched(fn: (emails: File | null) => void = () => {}): void {
    this.onTouched = fn;
  }
  public valuesChange: (emails: File | null) => void = () => {};
  public onTouched: (emails: File | null) => void = () => {};

  public internalValue: File | null = null;
  public isDragOver = false;
  public multipleFiles = false;
  @Input()
  public accept: string | null = null;

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.multipleFiles =
      !!event.dataTransfer?.files.length &&
      event.dataTransfer?.files.length > 1;
    this.isDragOver = true;
  }

  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.multipleFiles = false;
    this.isDragOver = false;
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
    this.multipleFiles = false;

    if (
      this.internalValue ||
      (!!event.dataTransfer?.files.length &&
        event.dataTransfer?.files.length > 1)
    ) {
      return;
    }

    if (event.dataTransfer?.files.length) {
      const file = event.dataTransfer?.files[0];
      this.internalValue = file;
      this.valuesChange(file);
    }
  }

  public onFileSelect(event: Event): void {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const file = target.files[0];
      target.value = '';
      this.valuesChange(file);
      this.internalValue = file;
    }
  }

  public onRemoveFile(): void {
    console.log('remove file', this.internalValue);
    this.internalValue = null;
    this.valuesChange(null);
  }
}
