import { Component } from '@angular/core';
import { EditSoftwareStateService } from './edit-software-state.service';
import {
  combineLatest,
  finalize,
  map,
  Observable,
  shareReplay,
  Subject,
  takeUntil
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DigitalTwinGetContentResponse } from '../../models/digital-twin-get-content-response';
import { Software } from '../../models/software';
import { SoftwareStateItem } from '../../models/software-state';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-software',
  templateUrl: './edit-software.component.html',
  styleUrls: ['./edit-software.component.scss'],
  providers: [EditSoftwareStateService]
})
export class EditSoftwareComponent {
  public _saving = false;
  public _destroy$ = new Subject<void>();
  public _changeset$: Observable<{ software: string; message: string }[]>;
  public _initialData$: Observable<{
    contentResponse: DigitalTwinGetContentResponse;
    software: Software[];
  }>;

  constructor(
    public _state: EditSoftwareStateService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _translation: TranslateService
  ) {
    this._initialData$ = this._state
      .initialise(_activatedRoute)
      .pipe(shareReplay(1), takeUntil(this._destroy$));

    this._changeset$ = combineLatest([
      this._initialData$,
      this._state.state$
    ]).pipe(
      map(([initialData, state]) => this.getChangeSet(initialData, state)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public getChangeSet(
    initialData: {
      contentResponse: DigitalTwinGetContentResponse;
      software: Software[];
    },
    state: SoftwareStateItem[]
  ): { software: string; message: string }[] {
    return [
      ...initialData.contentResponse.softwareState.software
        .filter(s => !state.some(i => s.name === i.name))
        .map(i => ({
          software: i.name,
          message: this._translation.instant('editSoftware.changeset.deleted', {
            software: i.name
          })
        })),
      ...state
        .filter(
          s =>
            !initialData.contentResponse.softwareState.software.some(
              i => s.name === i.name
            )
        )
        .map(i => ({
          software: i.name,
          message: this._translation.instant('editSoftware.changeset.added', {
            software: i.name,
            version: i.version
          })
        })),
      ...state
        .filter(s =>
          initialData.contentResponse.softwareState.software.some(
            i =>
              s.name === i.name &&
              (s.version != i.version ||
                s.variant != i.variant ||
                s.vehicleType != i.vehicleType)
          )
        )
        .map(i => ({
          software: i.name,
          message: this._translation.instant('editSoftware.changeset.updated', {
            software: i.name,
            version: i.version
          })
        }))
    ];
  }

  public saveChanges(
    initialData: {
      contentResponse: DigitalTwinGetContentResponse;
      software: Software[];
    },
    state: SoftwareStateItem[],
    changeset: { software: string; message: string }[]
  ): void {
    this._saving = true;
    this._state
      .saveChanges(initialData, state, this.getModificationNote(changeset))
      .pipe(finalize(() => (this._saving = false)))
      .subscribe(() =>
        this._router.navigate(['../'], { relativeTo: this._activatedRoute })
      );
  }

  public getModificationNote(
    changeset: { software: string; message: string }[]
  ): string {
    return `Manual software change (${changeset.map(i => i.software).join(', ')})`;
  }
}
