<div mat-dialog-title>
  <ui-title
    size="large"
    [title]="'node.showApplyBundleDialog.dialogtitle' | translate"
    state="success"
    [svgIcon]="true"
    icon="icon_AGV"
  ></ui-title>
</div>
<mat-dialog-content>
  @if (data.bundleVersions$ | async; as list) {
    @if (list && list.length > 0) {
      <ui-table
        [key]="'version'"
        [structure]="getTableStructure()"
        [elements]="list"
        [selectedElements]="selectedBundle"
        (selectedElementsChanges)="select($event)"
        selectionMode="Clickable"
        defaultSortActive="version"
        defaultSortDirection="desc"
      >
        <div *uiOverrideColumn="'status'; let element">
          <ui-tag
            [label]="'node.bundle.status.' + element.status | translate"
            [stateColor]="getTagColorByStatus(element.status || '')"
            suffixSvgIcon
          >
          </ui-tag>
        </div>
      </ui-table>
    } @else {
      {{ 'node.showApplyBundleDialog.noBundlesFound' | translate }}
      {{ data.vehicleType }}
    }
  } @else {
    {{ 'common.loading' | translate }} ...
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button type="button" (click)="cancel()">
    {{ 'common.cancel' | translate }}
  </button>
  <button
    mat-flat-button
    type="submit"
    [disabled]="preventSave()"
    (click)="submit()"
  >
    {{ 'node.showApplyBundleDialog.apply' | translate }}
  </button>
</mat-dialog-actions>
