import { Component, Input } from '@angular/core';
import { BlobInfo } from '../../models/blob-info';
import { DigitalTwinApiService } from '../../services/digital-twin-api.service';

@Component({
  selector: 'app-node-blobs',
  templateUrl: './node-blobs.component.html'
})
export class NodeBlobsComponent {
  @Input() public blobs: BlobInfo[] | null = null;

  constructor(public service: DigitalTwinApiService) {}
}
