@if (_initialData$ | async; as initialData) {
  @if (_state.state$ | async; as state) {
    <ui-shell-page-title [title]="'editSoftware.title' | translate">
    </ui-shell-page-title>
    <ui-card [border]="true" [rounded]="true">
      <ui-card-header>
        <ui-title
          size="small"
          state="success"
          [title]="'editSoftware.title' | translate"
          [subtitle]="initialData.contentResponse.info.vin"
          [svgIcon]="true"
          [icon]="'icon_bugfixes'"
        ></ui-title>
      </ui-card-header>
      @if (_changeset$ | async; as changeset) {
        <ul>
          <li *ngFor="let item of changeset">{{ item.message }}</li>
        </ul>
      }
      <ui-card-footer>
        <div class="button-group">
          <button
            type="button"
            mat-stroked-button
            color="primary"
            [routerLink]="['../']"
          >
            {{ 'common.cancel' | translate }}
          </button>
          @if (_changeset$ | async; as changeset) {
            <button
            type="button"
            mat-stroked-button
            color="primary"
            [disabled]="!changeset.length"
            (click)="_state.resetChanges(initialData)"
          >
            {{ 'common.reset' | translate }}
          </button>
            <button
              mat-flat-button
              color="primary"
              type="button"
              [disabled]="!changeset.length || _saving"
              (click)="saveChanges(initialData, state, changeset)"
            >
              {{ 'common.save' | translate }}
            </button>
          }
        </div>
      </ui-card-footer>
    </ui-card>

    <table [dataSource]="state" mat-table>
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'node.software.name' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>{{ item.name }}</td>
      </ng-container>
      <ng-container matColumnDef="version">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'node.software.version' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>
          {{ item.version }}
        </td>
      </ng-container>
      <ng-container matColumnDef="variant">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'node.software.variant' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>{{ item.variant }}</td>
      </ng-container>
      <ng-container matColumnDef="vehicleType">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'node.software.vehicleType' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>{{ item.vehicleType }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let item" mat-cell>
          <div class="buttonlist">
            <ui-icon-button
              size="medium"
              theme="secondary"
              svgIcon
              icon="icon_edit"
              [matTooltip]="'common.edit' | translate"
              (click)="_state.edit(item, initialData, state)"
            >
            </ui-icon-button>
            <ui-icon-button
              size="medium"
              theme="secondary"
              svgIcon
              icon="icon_action-delete"
              [matTooltip]="'common.delete' | translate"
              (click)="_state.delete(state, item)"
            >
            </ui-icon-button>
          </div>
        </td>
      </ng-container>
      <tr
        *matHeaderRowDef="[
          'name',
          'version',
          'variant',
          'vehicleType',
          'actions'
        ]"
        mat-header-row
      ></tr>
      <tr
        *matRowDef="
          let row;
          columns: ['name', 'version', 'variant', 'vehicleType', 'actions']
        "
        mat-row
      ></tr>
    </table>
    <div>
      <button
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="state.length === initialData.software.length"
        (click)="_state.add(initialData, state)"
      >
        {{ 'common.add' | translate }}
      </button>
    </div>
  }
}
