export interface BundleVersionStatus {
  version: string;
  status: BundleStatus;
}

export enum BundleStatus {
  Draft = 'draft',
  Integration = 'integration',
  Validation = 'validation',
  Released = 'released',
  Discarded = 'discarded',
  Rejected = 'rejected',
  Unknown = 'unknown'
}
