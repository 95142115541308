<div mat-dialog-title>
  <ui-title
    size="large"
    [title]="'editSoftware.editTitle' | translate"
    state="success"
    [svgIcon]="true"
    icon="icon_bugfixes"
  ></ui-title>
</div>
<mat-dialog-content>
  @if (sharedSoftwareVersions$ | async; as softwareVersions) {
    @if (softwareVersions.length > 0) {
      <ui-table
        [key]="'id'"
        [structure]="getTableStructure()"
        [elements]="softwareVersions"
        [selectedElements]="initialSelection"
        (selectedElementsChanges)="select($event)"
        selectionMode="Clickable"
        defaultSortActive="version"
        defaultSortDirection="desc"
      >
      </ui-table>
    } @else {
      {{ 'editSoftware.noVersionsFound' | translate }}
    }
  } @else {
    {{ 'common.loading' | translate }} ...
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button type="button" (click)="cancel()">
    {{ 'common.cancel' | translate }}
  </button>
  <button mat-flat-button type="button" [disabled]="preventSave()" (click)="submit()">
    {{ 'editSoftware.apply' | translate }}
  </button>
</mat-dialog-actions>
