<div mat-dialog-title>
  <ui-title
    size="large"
    [title]="'editSoftware.addTitle' | translate"
    state="success"
    [svgIcon]="true"
    icon="icon_bugfixes"
  ></ui-title>
</div>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>{{ 'editSoftware.selectSoftware' | translate }}</mat-label>
    <mat-select [(ngModel)]="selectedSoftware" (ngModelChange)="populateSoftwareVersions()">
      <mat-option [value]="software" *ngFor="let software of data.software">{{
        software.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  @if (selectedSoftware) {
    @if (applicableSoftwareVersions) {
      @if (applicableSoftwareVersions.length > 0) {
        <ui-table
          [key]="'id'"
          [structure]="getTableStructure()"
          [elements]="applicableSoftwareVersions"
          (selectedElementsChanges)="select($event)"
          selectionMode="Clickable"
          defaultSortActive="version"
          defaultSortDirection="desc"
        >
        </ui-table>
      } @else {
        {{ 'editSoftware.noVersionsFound' | translate }}
      }
    } @else {
      {{ 'common.loading' | translate }} ...
    }
  }

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button type="button" (click)="cancel()">
    {{ 'common.cancel' | translate }}
  </button>
  <button
    mat-flat-button
    type="button"
    [disabled]="preventSave()"
    (click)="submit()"
  >
    {{ 'editSoftware.apply' | translate }}
  </button>
</mat-dialog-actions>
