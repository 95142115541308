<div class="drag-drop-zone" [class.dragover]="isDragOver" [class.multiple-files-not-allowed]="multipleFiles"
    (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
    <mat-icon svgIcon="icon_doc-cad" color="primary" class="large-icon"></mat-icon>
    @if (!internalValue) {
    @if(!multipleFiles) {
    <p>{{'drag-and-drop.drag-instruction' | translate}}<br><button
            mat-button (click)="fileInput.click()">{{'drag-and-drop.click-instruction' | translate}}</button></p>
    }
    @else {
    <p>{{'drag-and-drop.one-file' | translate}}</p>
    }
    }
    @else {
    <p>{{internalValue.name}} <mat-icon class="hover" (click)="onRemoveFile()">close</mat-icon></p>
    <ng-content></ng-content>
    }
    <input type="file" [formControl]="inputControl" (change)="onFileSelect($event)" hidden #fileInput
        [accept]="accept" (cancel)="$event.stopPropagation()"/>
</div>
