import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AsyncValidatorsService } from '../../services/async-validators.service';

@Component({
  selector: 'app-create-by-tdf',
  templateUrl: './create-by-tdf.component.html'
})
export class CreateByTdfComponent {
  @Output() public create = new EventEmitter<{ vin: string; file: File }>();
  public _form: FormGroup;
  public _file: File | null = null;

  constructor(
    builder: FormBuilder,
    private _asyncValidators: AsyncValidatorsService,
    private _dialogRef: MatDialogRef<CreateByTdfComponent>
  ) {
    this._form = builder.group({
      vin: [
        null,
        [Validators.required],
        [this._asyncValidators.vinUniquenessValidator()]
      ],
      file: [null, [Validators.required]]
    });
  }

  public onFileSelected(evt: Event): void {
    const target = evt.target as HTMLInputElement;
    if (target.files) {
      this._file = target.files[0];
    } else {
      this._file = null;
    }
  }

  public cancel(): void {
    this._dialogRef.close(null);
  }

  public submit(): void {
    if (this._form.valid) {
      this._dialogRef.close(this._form.value);
    }
  }
}
