import { BundleStatus } from '../../models/bundle-version-status';

/**
 * Used to get the color of the tag based on status
 */
export function getTagColorByStatus(status: string): string {
  switch (status) {
    case BundleStatus.Draft: {
      return '#6c9fd2';
    }
    case BundleStatus.Integration: {
      return '#b59bd5';
    }
    case BundleStatus.Validation: {
      return '#fcb76e';
    }
    case BundleStatus.Released: {
      return '#6ec58f';
    }
    case BundleStatus.Rejected: {
      return '#e57c71';
    }
    case BundleStatus.Discarded: {
      return '#a3a3a3';
    }
    default: {
      return '#a3a3a3';
    }
  }
}
