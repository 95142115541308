import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class AuthConfigService {
  constructor(@Inject(APP_CONFIG) private _config: AppConfig) {}

  public getConfig(): OpenIdConfiguration {
    return {
      authority: this._config.identity.authority,
      authWellknownEndpointUrl: this._config.identity.authWellknownEndpointUrl,
      clientId: this._config.identity.clientId,
      redirectUrl: this._config.identity.redirectUrl,
      postLogoutRedirectUri: this._config.identity.redirectUrl,
      logLevel: LogLevel.Warn,
      secureRoutes: this._config.identity.securedEndpoints,
      responseType: 'code',
      useRefreshToken: true,
      silentRenew: true,
      scope: this._config.identity.scopes,
      ignoreNonceAfterRefresh: true,
      renewUserInfoAfterTokenRenew: true,
      autoUserInfo: false,
      issValidationOff: true,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      customParamsAuthRequest: {
        prompt: 'select_account' // login, consent
      }
    };
  }
}
