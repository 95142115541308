<ng-container *ngIf="requestOverviewDetail$ | async as requestOverviewDetails">
  <ui-shell-sidepanel-content
    [title]="requestOverviewDetails?.sequenceNumber || ''"
    [(active)]="showSidePanel"
  >
    <app-request-overview-sidepane
      [item]="requestOverviewDetails"
      (downloadAllReceived)="_service.downloadReceivedData($event)"
      (downloadTDF)="_service.downloadTDF($event)"
      (viewTDF)="_service.openTDF($event)"
    />
  </ui-shell-sidepanel-content>
</ng-container>

<div class="custom-layout full">
  <ui-shell-panel class="main">
    <div class="topbar">
      <h3>{{ 'requestOverview.title' | translate }}</h3>
      <button type="button" mat-stroked-button (click)="showCreateDialog()">{{'requestOverview.uploadTdf' | translate}}</button>
    </div>

    <ng-container *ngIf="requestOverview$ | async as requestOverview">
      <ui-table
        [key]="'id'"
        [structure]="tableElements$ | async"
        [elements]="requestOverview"
        selectionMode="Clickable"
        defaultSortActive="createdOn"
        (selectedElementsChanges)="showDetail($event)"
      >
        <div *uiOverrideColumn="'vin'; let element">
          <ui-title
            size="small"
            [state]="element.errorCode === '00000000' ? 'success' : 'error'"
            [iconTooltip]="element.errorDetails"
            [title]="element.vin"
            [subtitle]="element.sequenceNumber"
            [svgIcon]="true"
            [icon]="
              element.errorCode === '00000000'
                ? 'icon_commissioning'
                : 'icon_error'
            "
          ></ui-title>
        </div>
        <div *uiOverrideColumn="'createdOn'; let element">
          {{ element.createdOn | date: 'medium' }}
        </div>
        <div *uiOverrideColumn="'matchingNode'; let element">
          <span *ngIf="element.matchingNode"
            ><mat-icon svgIcon="icon_check"></mat-icon
          ></span>
          <span *ngIf="!element.matchingNode"
            ><mat-icon svgIcon="icon_close"></mat-icon
          ></span>
        </div> </ui-table
    ></ng-container>
  </ui-shell-panel>
</div>
