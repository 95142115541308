import { Component, Inject } from '@angular/core';
import { ApplyBundleDialogData } from '../../models/apply-bundle-dialog-data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getTagColorByStatus } from '../../shared/functions/tag-color-by-status.func';
import { BundleVersionStatus } from '../../models/bundle-version-status';
import { TranslateService } from '@ngx-translate/core';
import { TableElement } from '@onyx/core';

@Component({
  selector: 'app-apply-bundle-dialog',
  templateUrl: './apply-bundle-dialog.component.html',
  styleUrls: ['./apply-bundle-dialog-component.scss']
})
export class ApplyBundleDialogComponent {
  public readonly getTagColorByStatus = getTagColorByStatus;
  public selectedBundle: BundleVersionStatus[] | null = null;

  constructor(
    private _dialogRef: MatDialogRef<ApplyBundleDialogComponent>,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ApplyBundleDialogData
  ) {}

  public submit(): void {
    if (this.selectedBundle?.length) {
      this._dialogRef.close(this.selectedBundle[0].version);
    }
  }

  public cancel(): void {
    this._dialogRef.close(null);
  }

  public getTableStructure(): TableElement<BundleVersionStatus>[] {
    return [
      {
        field: 'version',
        header: this._translateService.instant('software.version'),
        filterable: true
      },
      {
        field: 'status',
        header: this._translateService.instant('node.bundle.status.title'),
        filterable: true
      }
    ];
  }

  public select(selection: BundleVersionStatus[]): void {
    this.selectedBundle = selection;
  }

  public preventSave(): boolean {
    return !this.selectedBundle?.length;
  }
}
