import { Injectable } from '@angular/core';
import { DigitalTwinApiService } from './digital-twin-api.service';
import { catchError, delay, map, Observable, of, switchMap } from 'rxjs';
import { DigitalTwinNode } from '../models/digital-twin-node';
import { DigitalTwinNodeRelationsEnum } from '../models/digital-twin-node-relations.enum';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class AsyncValidatorsService {
  private _debounceTime = 300;

  constructor(private _service: DigitalTwinApiService) {}

  public getNodeByVin(vin: string): Observable<DigitalTwinNode | null> {
    if (!vin || vin.length < 4) {
      return of(null);
    }
    return this._service
      .getNodeByVin(vin, DigitalTwinNodeRelationsEnum.None)
      .pipe(catchError(() => of(null)));
  }

  public vinUniquenessValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.value.length < 4) {
        return of(null);
      }
      return of(control.value).pipe(
        delay(this._debounceTime),
        switchMap(() => this.getNodeByVin(control.value)),
        map(res => {
          return res ? { vinConflict: true } : null;
        })
      );
    };
  }

  public vinAvailabilityValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.value.length < 4) {
        return of(null);
      }
      return of(control.value).pipe(
        delay(this._debounceTime),
        switchMap(() => this.getNodeByVin(control.value)),
        map(res => {
          return res ? null : { invalidVin: true };
        })
      );
    };
  }
}
