import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first, Observable, shareReplay } from 'rxjs';
import { SoftwareVersion } from '../../../models/software-version';
import { TranslateService } from '@ngx-translate/core';
import { SoftwareStateItem } from '../../../models/software-state';
import { TableElement } from '@onyx/core';

@Component({
  selector: 'app-edit-software-edit-dialog',
  templateUrl: './edit-software-edit-dialog.component.html'
})
export class EditSoftwareEditDialogComponent {
  public initialSelection: SoftwareVersion[] = [];
  public userSelection: SoftwareVersion[] | null = null;
  public sharedSoftwareVersions$: Observable<SoftwareVersion[]>;

  constructor(
    public _translateService: TranslateService,
    private _dialogRef: MatDialogRef<EditSoftwareEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      current: SoftwareStateItem;
      softwareVersions$: Observable<SoftwareVersion[]>;
    }
  ) {
    this.sharedSoftwareVersions$ = data.softwareVersions$.pipe(shareReplay(1));
    this.sharedSoftwareVersions$
      .pipe(first())
      .subscribe(
        list =>
          (this.initialSelection = this.getSelectedElements(data.current, list))
      );
  }

  public getTableStructure(): TableElement<SoftwareVersion>[] {
    return [
      {
        field: 'version',
        header: this._translateService.instant('software.version'),
        filterable: true
      },
      {
        field: 'variant',
        header: this._translateService.instant('software.variant'),
        filterable: true
      },
      {
        field: 'vehicleType',
        header: this._translateService.instant('software.vehicleType'),
        filterable: true
      }
    ];
  }

  public getSelectedElements(
    current: SoftwareStateItem,
    softwareVersions: SoftwareVersion[]
  ): SoftwareVersion[] {
    return softwareVersions.filter(
      i =>
        i.version == current.version &&
        i.variant == current.variant &&
        i.vehicleType == current.vehicleType
    );
  }

  public preventSave(): boolean {
    return !(
      !!this.userSelection?.length &&
      (this.initialSelection.length == 0 ||
        this.userSelection[0].id !== this.initialSelection[0].id)
    );
  }

  public select(selection: SoftwareVersion[]): void {
    this.userSelection = selection;
  }

  public submit(): void {
    if (this.userSelection?.length) {
      this._dialogRef.close(this.userSelection[0]);
    }
  }

  public cancel(): void {
    this._dialogRef.close(null);
  }
}
