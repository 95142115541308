<ui-shell-content-header [sticky]="true">
  <ui-shell-page-title [title]="'home.title' | translate">
  </ui-shell-page-title>
  <ui-filter class="top" [control]="searchControl" [hotpicks]="true">
    <ui-hotpick
      [active]="true"
      [matMenuTriggerFor]="menu"
      label="{{ 'home.searchBar.searchBy' | translate }}"
    >
      @if (selectedSearchType === 'vin') {
        <span>{{ 'home.searchBar.vinType' | translate }}</span>
      } @else {
        <span>{{ 'home.searchBar.nodeNameType' | translate }}</span>
      }
      <mat-icon>arrow_drop_down</mat-icon>
    </ui-hotpick></ui-filter
  >
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="selectSearchType('vin')">
      {{ 'home.searchBar.vinType' | translate }}
    </button>
    <button mat-menu-item (click)="selectSearchType('name')">
      {{ 'home.searchBar.nodeNameType' | translate }}
    </button>
  </mat-menu>
  <ng-container *ngIf="_nodes$ | async as nodes">
    <ui-card
      [border]="true"
      [rounded]="true"
      *ngFor="let rootNode of nodes.nodes"
      [routerLink]="['node', rootNode.id]"
    >
      <ui-card-header>
        <ui-title
          size="small"
          state="success"
          [title]="rootNode.name"
          [subtitle]="_nodeTypeEnum[rootNode.nodeType]"
          [svgIcon]="true"
          [icon]="
            rootNode.nodeType === _nodeTypeEnum.Group
              ? 'nav_management'
              : 'icon_commissioning'
          "
        ></ui-title>
      </ui-card-header>
    </ui-card>
  </ng-container>
</ui-shell-content-header>
