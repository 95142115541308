import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetNodesRequest } from '../models/get-nodes-request';
import { RequestOverviewItem } from '../models/request-overview-item';
import { GetNodesResponse } from '../models/get-nodes-response';
import { DigitalTwinNodeRelationsEnum } from '../models/digital-twin-node-relations.enum';
import { DigitalTwinNode } from '../models/digital-twin-node';
import { DigitalTwinGetContentResponse } from '../models/digital-twin-get-content-response';
import { CreateVehicleRequest } from '../models/create-vehicle-request';
import { SaveNodeResponse } from '../models/save-node-response';
import { BundleDeviation } from '../models/bundle-deviation';
import { InstalledVersionInfo } from '../models/installed-version-info';
import { GetNodeHistoryDiffRequest } from '../models/get-node-history-diff-request';
import { DigitalTwinDiff } from '../models/digital-twin-diff';
import { Software } from '../models/software';
import { SoftwareVersion } from '../models/software-version';
import { DigitalTwinSaveContentRequest } from '../models/digital-twin-save-content-request';
import { DigitalTwinSaveContentResponse } from '../models/digital-twin-save-content-response';
import { APP_CONFIG, AppConfig } from '../config/app.config';
import { BundleVersionStatus } from '../models/bundle-version-status';

@Injectable({ providedIn: 'root' })
export class DigitalTwinApiService {
  private _uri: string;

  constructor(
    private _http: HttpClient,
    @Inject(APP_CONFIG) config: AppConfig
  ) {
    this._uri = config.cloudApiUri;
  }

  public createByTdf(vin: string, file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);

    return this._http.post<void>(
      `${this._uri}api/tdf/trigger/${vin}`,
      formData
    );
  }

  public revert(
    digitalTwinId: number,
    version: number
  ): Observable<SaveNodeResponse> {
    return this._http.post<SaveNodeResponse>(
      `${this._uri}api/v3/store/twin/${digitalTwinId}/content/revert/${version}`,
      null
    );
  }

  public getInstalledVersions(
    nodeId: number
  ): Observable<InstalledVersionInfo[]> {
    return this._http.get<InstalledVersionInfo[]>(
      `${this._uri}api/v3/store/twin/${nodeId}/installed-versions`
    );
  }

  public getBundleDeviations(nodeId: number): Observable<BundleDeviation[]> {
    return this._http.get<BundleDeviation[]>(
      `${this._uri}api/v3/store/twin/${nodeId}/bundle-deviations`
    );
  }

  public getVehicleTypes(): Observable<string[]> {
    return this._http.get<string[]>(`${this._uri}api/v3/store/vehicleTypes`);
  }

  public getBundleVersionsByVehicleType(
    vehicleType: string
  ): Observable<BundleVersionStatus[]> {
    return this._http.get<BundleVersionStatus[]>(
      `${this._uri}api/v1/releasemanager/bundleversions`,
      {
        params: { vehicleType }
      }
    );
  }

  public applyBundleVersions(
    nodeIdList: number[],
    vehicleType: string,
    bundleVersion: string
  ): Observable<number[]> {
    return this._http.post<number[]>(
      `${this._uri}api/v1/releasemanager/applybundle`,
      nodeIdList,
      {
        params: { vehicleType, bundleVersion }
      }
    );
  }

  public getNodeById(
    id: number,
    relations: DigitalTwinNodeRelationsEnum
  ): Observable<DigitalTwinNode> {
    return this._http.get<DigitalTwinNode>(
      `${this._uri}api/v3/store/twin/${id}`,
      {
        params: { relations: relations || DigitalTwinNodeRelationsEnum.None }
      }
    );
  }

  public deleteNodeById(id: number): Observable<object> {
    return this._http.delete(`${this._uri}api/v3/store/twin/${id}`);
  }

  public getNodeByVin(
    vin: string,
    relations: DigitalTwinNodeRelationsEnum
  ): Observable<DigitalTwinNode> {
    return this._http.get<DigitalTwinNode>(
      `${this._uri}api/v3/store/twin/vin/${vin}`,
      {
        params: { relations: relations || DigitalTwinNodeRelationsEnum.None }
      }
    );
  }

  public getNodeContentById(
    id: number
  ): Observable<DigitalTwinGetContentResponse> {
    return this._http.get<DigitalTwinGetContentResponse>(
      `${this._uri}api/v3/store/twin/${id}/content`
    );
  }

  public searchNode(request: GetNodesRequest): Observable<GetNodesResponse> {
    return this._http.post<GetNodesResponse>(
      `${this._uri}api/v3/store/search`,
      request
    );
  }

  public addVehicle(
    request: CreateVehicleRequest
  ): Observable<SaveNodeResponse> {
    return this._http.post<SaveNodeResponse>(
      `${this._uri}api/v3/store/twin/create`,
      request
    );
  }

  public saveContent(
    digitalTwinId: number,
    request: DigitalTwinSaveContentRequest
  ): Observable<DigitalTwinSaveContentResponse> {
    return this._http.post<DigitalTwinSaveContentResponse>(
      `${this._uri}api/v3/store/twin/${digitalTwinId}/content`,
      request
    );
  }

  public getTFDRequestOverview(): Observable<RequestOverviewItem[]> {
    return this._http.get<RequestOverviewItem[]>(`${this._uri}api/tdf/get-all`);
  }

  public downloadReceivedData(item: RequestOverviewItem): void {
    this._downloadJsonResponse(
      `${this._uri}api/tdf/get-received-data/${item.id}`,
      `${item.sequenceNumber}_data.json`
    );
  }

  public downloadTDF(item: RequestOverviewItem): void {
    this._downloadJsonResponse(
      `${this._uri}api/tdf/get-tdf/${item.id}`,
      `${item.sequenceNumber}_tdf.json`
    );
  }

  public openTDF(item: RequestOverviewItem): void {
    this._downloadJsonResponse(
      `${this._uri}api/tdf/get-tdf/${item.id}`,
      `${item.sequenceNumber}_tdf.json`,
      true
    );
  }

  public getUserRoles(): Observable<string[]> {
    return this._http.get<string[]>(`${this._uri}api/v1/user`);
  }

  public getNodeDiff(
    request: GetNodeHistoryDiffRequest
  ): Observable<DigitalTwinDiff> {
    return this._http.post<DigitalTwinDiff>(
      `${this._uri}api/v3/store/twin/history/diff`,
      request
    );
  }

  public getSoftware(): Observable<Software[]> {
    return this._http.get<Software[]>(`${this._uri}api/v1/software`);
  }

  public getSoftwareVersions(
    softwareId: string,
    vehicleType: string
  ): Observable<SoftwareVersion[]> {
    return this._http.get<SoftwareVersion[]>(
      `${this._uri}api/v1/software/${softwareId}/versions`,
      {
        params: { vehicleType }
      }
    );
  }

  public downloadBlob(remoteBlobUri: string): void {
    // look for filename, should be last section before hash:
    // /radiod.conf/53196C4503192456F04AD0174B7CFA494CD8E34BEE3E5F199C591D1FE7FD88D2.conf
    const match =
      /\/(?<filename>[a-zA-Z0-9-_.]+)\/[a-fA-F0-9]+(\.[a-zA-Z0-9]+)?$/gm.exec(
        remoteBlobUri
      );

    this._downloadBlobResponse(
      `${this._uri}api/BlobStorage/download?blobDownloadUri=${remoteBlobUri}`,
      match && match.groups ? match.groups['filename'] : null
    );
  }

  private _downloadJsonResponse(
    uri: string,
    filename: string,
    viewOnly: boolean = false
  ): void {
    this._http
      .get(uri, {
        responseType: 'text'
      })
      .subscribe(response => {
        const newBlob = new Blob([response], { type: 'text/json' });
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = data;
        if (!viewOnly) {
          link.download = filename;
        }
        link.click();
        window.URL.revokeObjectURL(link.href);
      });
  }

  private _downloadBlobResponse(uri: string, filename?: string | null): void {
    this._http
      .get(uri, {
        responseType: 'blob'
      })
      .subscribe(response => {
        const newBlob = new Blob([response], {
          type: 'application/octet-stream'
        });

        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = data;
        if (filename) {
          link.download = filename;
        }
        link.click();
        window.URL.revokeObjectURL(link.href);
      });
  }
}
