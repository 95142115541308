<div mat-dialog-title>
  <ui-title
    size="default"
    [title]="
      'diff.title'
        | translate: { versionA: data.versionA, versionB: data.versionB }
    "
    [subtitle]="data.modificationNote"
  ></ui-title>
</div>
<mat-dialog-content>
  @if (data.diff$ | async; as diff) {
    <app-node-diff [diff]="diff" />
  } @else {
    <p>{{ 'common.loading' | translate }} ...</p>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button type="button" (click)="close()">
    {{ 'common.close' | translate }}
  </button>
</mat-dialog-actions>
