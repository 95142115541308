<ng-container *ngIf="blobs">
  <table [dataSource]="blobs" mat-table>
    <ng-container matColumnDef="relativeLocalPath">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.blobs.path' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        <a href="#" (click)="service.downloadBlob(item.blobUri); $event.preventDefault()">{{ item.relativeLocalPath }}</a>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="['relativeLocalPath']" mat-header-row></tr>
    <tr *matRowDef="let row; columns: ['relativeLocalPath']" mat-row></tr>
  </table>
</ng-container>
