import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  shareReplay,
  switchMap
} from 'rxjs';
import { DigitalTwinGetContentResponse } from '../../models/digital-twin-get-content-response';
import { SoftwareStateItem } from '../../models/software-state';
import { Software } from '../../models/software';
import { ActivatedRoute } from '@angular/router';
import { DigitalTwinApiService } from '../../services/digital-twin-api.service';
import { clone } from '../../shared/functions/clone';
import { SoftwareVersion } from '../../models/software-version';
import { MatDialog } from '@angular/material/dialog';
import { EditSoftwareEditDialogComponent } from './edit-dialog/edit-software-edit-dialog.component';
import { EditSoftwareAddDialogComponent } from './add-dialog/edit-software-add-dialog.component';
import { DigitalTwinSaveContentResponse } from '../../models/digital-twin-save-content-response';

@Injectable()
export class EditSoftwareStateService {
  private _internalState$ = new BehaviorSubject<SoftwareStateItem[]>([]);
  public state$ = this._internalState$.pipe(
    map(list => list.sort((a, b) => a.name.localeCompare(b.name))),
    shareReplay(1)
  );

  constructor(
    private _service: DigitalTwinApiService,
    private _dialog: MatDialog
  ) {}

  public initialise(activatedRoute: ActivatedRoute): Observable<{
    contentResponse: DigitalTwinGetContentResponse;
    software: Software[];
  }> {
    const loadContent$ = activatedRoute.paramMap.pipe(
      switchMap(params => {
        return this._service.getNodeContentById(
          parseInt(params.get('id')!, 10)
        );
      })
    );

    const loadSoftware$ = this._service.getSoftware();

    return combineLatest([loadContent$, loadSoftware$]).pipe(
      map(([contentResponse, software]) => {
        this._internalState$.next(
          clone(contentResponse.softwareState.software)
        );
        return {
          contentResponse,
          software
        };
      })
    );
  }

  public saveChanges(
    initialData: {
      contentResponse: DigitalTwinGetContentResponse;
      software: Software[];
    },
    software: SoftwareStateItem[],
    modificationNote: string
  ): Observable<DigitalTwinSaveContentResponse> {
    const payload = { ...initialData.contentResponse.softwareState, software };
    return this._service.saveContent(initialData.contentResponse.info.id, {
      digitalTwinVersion: initialData.contentResponse.info.version,
      softwareState: payload,
      modificationNote: modificationNote
    });
  }

  public resetChanges(initialData: {
    contentResponse: DigitalTwinGetContentResponse;
    software: Software[];
  }): void {
    this._internalState$.next(
      clone(initialData.contentResponse.softwareState.software)
    );
  }

  public add(
    initialData: {
      contentResponse: DigitalTwinGetContentResponse;
      software: Software[];
    },
    state: SoftwareStateItem[]
  ): void {
    const software = initialData.software.filter(
      i => !state.some(s => s.name === i.name)
    );

    const getSoftwareVersions = (
      software: string
    ): Observable<SoftwareVersion[]> =>
      this.getVersionsForVehicle(initialData, software);

    const dialogRef = this._dialog.open(EditSoftwareAddDialogComponent, {
      data: { software, getSoftwareVersions },
      panelClass: 'info'
    });

    dialogRef
      .afterClosed()
      .subscribe((values: { version: SoftwareVersion; software: Software }) => {
        if (values) {
          this._internalState$.next([
            ...state,
            {
              name: values.software.name,
              version: values.version.version,
              variant: values.version.variant,
              vehicleType: values.version.vehicleType,
              relativePath: values.version.relativePath,
              remoteLocation: values.version.remoteLocation,
              requiredFiles: null
            }
          ]);
        }
      });
  }

  public delete(
    currentState: SoftwareStateItem[],
    toDelete: SoftwareStateItem
  ): void {
    this._internalState$.next(currentState.filter(i => i !== toDelete));
  }

  public edit(
    current: SoftwareStateItem,
    initialData: {
      contentResponse: DigitalTwinGetContentResponse;
      software: Software[];
    },
    state: SoftwareStateItem[]
  ): void {
    const softwareVersions$ = this.getVersionsForVehicle(
      initialData,
      current.name
    );
    const dialogRef = this._dialog.open(EditSoftwareEditDialogComponent, {
      data: { current, softwareVersions$ },
      panelClass: 'info'
    });
    dialogRef.afterClosed().subscribe((version: SoftwareVersion) => {
      if (version) {
        current.version = version.version;
        current.variant = version.variant;
        current.vehicleType = version.vehicleType;
        this._internalState$.next([...state]);
      }
    });
  }

  public getVersionsForVehicle(
    initialData: {
      contentResponse: DigitalTwinGetContentResponse;
      software: Software[];
    },
    softwareName: string
  ): Observable<SoftwareVersion[]> {
    const softwareId = initialData.software.find(
      i => i.name.toUpperCase() === softwareName.toUpperCase()
    )?.id;
    if (softwareId) {
      return this._service.getSoftwareVersions(
        softwareId,
        initialData.contentResponse.info.vehicleType
      );
    }
    console.warn(`Unknown software name ${softwareName}`);
    return of([]);
  }
}
